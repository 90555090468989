import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, preventSubmit } from '@cp-shared-8/frontend-ui';
import { ArrearsStatuses, nowCPDate, parseCPDate } from '@cp-uk/common';
import { textWithComponents, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedRadioButtons } from 'components/validated-radio-buttons/ValidatedRadioButtons';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { outstandingPaymentsPagePath } from 'components/navigation/paths';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { MethodsOfPayment, PayCommonViewProps, SummaryItem } from '../types';
import { validationSchema } from './PayInOneGoValidation';
import {
    dateOfPaymentDisabled,
    dateOfPaymentIsMandatory,
    getMethodOfPaymentRadioButtons,
    getActionNotificationContent,
} from '../helpers';
import { buildInitialValues, buildSelections, buildSummaryItem } from './helpers';
import { PayInOneGoFormValues, PayInOneGoSelections } from './types';

export type PayInOneGoViewProps = PayCommonViewProps & {
    encryptedContractId: string;
    arrearsStatus: ArrearsStatuses;
    currentSelections: PayInOneGoSelections | undefined;
    onContinue: (selections: PayInOneGoSelections, summaryItem: SummaryItem) => void;
};

export const PayInOneGoView: React.FC<PayInOneGoViewProps> = ({
    encryptedContractId,
    contractDescription,
    totalArrears,
    arrearsStatus,
    changeBankAccountInProgress,
    changePaymentDateInProgress,
    sortCode,
    accountNumber,
    lastBilledPaymentDate,
    nextScheduledPaymentDate,
    journeyId,
    currentOptionTitle,
    currentSelections,
    onBack,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-pay-in-one-go-view');

    const backButtonTestId = 'backButton';
    const continueButtonTestId = 'continueButton';
    const trackingId = 'requestAdditionalHelpPayInOneGo';
    useAnalyticsPageViewTracker(trackingId, !!contractDescription, journeyId);
    const { onAction: onBackAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onContinueAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onOutstandingPaymentsLinkAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onDashboardLinkAction } = useAnalyticsActionTracker(trackingId);

    const todayMoment = nowCPDate().toMoment().startOf('day');
    const minValidDate = todayMoment.clone().add(1, 'day');
    const maxValidDate = todayMoment.clone().add(30, 'day');
    const inBillingPeriod = parseCPDate(lastBilledPaymentDate).toMoment().isSameOrAfter(todayMoment, 'day');
    const noRegularPayments = !nextScheduledPaymentDate;

    const directDebitDisabled =
        changeBankAccountInProgress || changePaymentDateInProgress || inBillingPeriod || noRegularPayments;

    const methodOfPaymentRadioButtons = getMethodOfPaymentRadioButtons(t, directDebitDisabled);

    const onOutstandingPaymentsClick = (): void => {
        onOutstandingPaymentsLinkAction('outstandingPaymentsPageLink', journeyId);
    };

    const linkToOutstandingPaymentsPage = (
        <Link to={outstandingPaymentsPagePath(encryptedContractId)} onClick={onOutstandingPaymentsClick} data-testid={'outstandingPaymentsPageLink'} />
    );

    const doesNotFeelRightCommonFragment = (): React.ReactNode => {
        return textWithComponents(t, 'paragraphs.doesNotFeelRightCommon');
    };

    const doesNotFeelRightConditionalFragment = (): React.ReactNode => {
        if (arrearsStatus !== 'payment' && arrearsStatus !== 'paymentAndHelp') {
            return null;
        }

        return textWithComponents(t, 'paragraphs.doesNotFeelRightConditional', { linkToOutstandingPaymentsPage });
    };

    const doesNotFeelRightParagraphContent = (): React.ReactNode => {
        return (
            <Fragment>
                {doesNotFeelRightCommonFragment()} {doesNotFeelRightConditionalFragment()}
            </Fragment>
        );
    };

    const letUsKnowHowAndWhenCommonFragment = (): React.ReactNode => {
        const options = {
            totalArrears: (totalArrears ?? 0) > 0 ? totalArrears : undefined,
        };

        return textWithComponents(t, 'paragraphs.letUsKnowHowAndWhenCommon', undefined, options);
    };

    const letUsKnowHowAndWhenSpecificFragment = (): React.ReactNode => {
        let key: string;
        let options: object | undefined = undefined;
        if (changeBankAccountInProgress && changePaymentDateInProgress) {
            key = 'ChangeBankAccountAndPaymentDateInProgress';
        } else if (changeBankAccountInProgress) {
            key = 'ChangeBankAccountOnlyInProgress';
        } else if (changePaymentDateInProgress) {
            key = 'ChangePaymentDateOnlyInProgress';
        } else if (inBillingPeriod) {
            key = 'InBillingPeriod';
        } else if (noRegularPayments) {
            key = 'NoRegularPayments';
        } else {
            key = 'Normal';
            options = { nextScheduledPaymentDate };
        }

        return textWithComponents(t, `paragraphs.letUsKnowHowAndWhen${key}`, undefined, options);
    };

    const letUsKnowHowAndWhenParagraphContent = (): React.ReactNode => {
        return (
            <Fragment>
                {letUsKnowHowAndWhenCommonFragment()} {letUsKnowHowAndWhenSpecificFragment()}
            </Fragment>
        );
    };

    const initialValues = buildInitialValues(currentSelections);

    const resetDateOfPayment = ({ setFieldValue, setFieldTouched }: FormikProps<PayInOneGoFormValues>): void => {
        setFieldValue('dateOfPayment', '', false);
        setFieldTouched('dateOfPayment', false, false);
    };

    const onMethodOfPaymentChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<PayInOneGoFormValues>,
    ): void => {
        if (newValue === 'directDebit') {
            resetDateOfPayment(formik);
        }
    };

    const onBackClick = (): void => {
        onBackAction(backButtonTestId, journeyId);
        onBack();
    };

    return (
        <View testId={'payInOneGoView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading', { currentOptionTitle })}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph className={'u-mb-large'} testId={'introductionParagraph'}>
                {t('paragraphs.introduction')}
            </Paragraph>
            <Paragraph testId={'doesNotFeelRightParagraph'}>{doesNotFeelRightParagraphContent()}</Paragraph>
            <Paragraph className={'u-mb-large'} testId={'happyToProceedParagraph'}>
                {textWithComponents(t, 'paragraphs.happyToProceed')}
            </Paragraph>
            <Paragraph testId={'letUsKnowHowAndWhenParagraph'}>{letUsKnowHowAndWhenParagraphContent()}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, minValidDate, maxValidDate)}
                onSubmit={(values): void => {
                    const selections = buildSelections(t, nextScheduledPaymentDate, values);

                    const summaryItem = buildSummaryItem(t, totalArrears, nextScheduledPaymentDate, values);

                    onContinueAction(continueButtonTestId, journeyId);
                    onContinue(selections, summaryItem);
                }}
            >
                {(formik: FormikProps<PayInOneGoFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-pay-in-one-go-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedRadioButtons
                                    className={'u-indent u-mb-xsmall'}
                                    label={t('methodOfPayment.label')}
                                    radioButtons={methodOfPaymentRadioButtons}
                                    name={'methodOfPayment'}
                                    testId={'methodOfPayment'}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                        onMethodOfPaymentChange(e, formik);
                                    }}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        numericOnly: true,
                                        blocks: [2, 2, 4],
                                        delimiter: '/',
                                    }}
                                    className={'input-date'}
                                    label={t('dateOfPayment.label')}
                                    tooltip={t('dateOfPayment.tooltip', { minValidDate, maxValidDate })}
                                    placeholder={t('dateOfPayment.placeholder')}
                                    name={'dateOfPayment'}
                                    testId={'dateOfPayment'}
                                    isMandatory={dateOfPaymentIsMandatory(
                                        formik.values.methodOfPayment as MethodsOfPayment,
                                    )}
                                    disabled={dateOfPaymentDisabled(formik.values.methodOfPayment as MethodsOfPayment)}
                                />
                            </Fieldset.Row>
                            {!!formik.values.methodOfPayment && (
                                <Fieldset.Row>
                                    <Notification
                                        status={NotificationStatus.info}
                                        testId={'actionNotification'}
                                        text={' '}
                                    >
                                        {getActionNotificationContent(
                                            t,
                                            formik.values.methodOfPayment as MethodsOfPayment,
                                            sortCode,
                                            accountNumber,
                                            onDashboardLinkAction,
                                            journeyId,
                                        )}
                                    </Notification>
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button type={'button'} testId={backButtonTestId} onClick={onBackClick} secondary>
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button type={'button'} testId={continueButtonTestId} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification trackingId={trackingId} journeyId={journeyId} />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
