import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { preventSubmit } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO } from '@cp-uk/common';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedCheckboxGroup } from 'components/validated-checkbox-group/ValidatedCheckboxGroup';
import { ValidatedTextarea } from 'components/validated-textarea/ValidatedTextarea';
import {
    RadioButtonProps,
    ValidatedRadioButtonGroup,
} from 'components/validated-radio-button-group/ValidatedRadioButtonGroup';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { SummaryItem } from '../types';
import { validationSchema } from './AdditionalInformationValidation';
import { AdditionalInformationFormValues, AdditionalInformationSelections, Buttons } from './types';
import {
    additionalSupportDetailDisabled,
    additionalSupportDetailIsMandatory,
    buildInitialValues,
    buildSelections,
    buildSummaryItem,
    financialCircumstancesMoreDetailIsMandatory,
} from './helpers';

export type AdditionalInformationViewProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number | undefined;
    journeyId: string;
    currentOptionTitle: string;
    currentSelections: AdditionalInformationSelections | undefined;
    onBack: () => void;
    onContinue: (selections: AdditionalInformationSelections, summaryItem: SummaryItem) => void;
};

export const AdditionalInformationView: React.FC<AdditionalInformationViewProps> = ({
    contractDescription,
    totalArrears,
    journeyId,
    currentOptionTitle,
    currentSelections,
    onBack,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-additional-information-view');

    const backButtonTestId = 'backButton';
    const continueButtonTestId = 'continueButton';
    const trackingId = 'requestAdditionalHelpAdditionalInformation';
    useAnalyticsPageViewTracker(trackingId, !!contractDescription, journeyId);
    const { onAction: onBackAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onContinueAction } = useAnalyticsActionTracker(trackingId);

    const noAndYesRadioButtons: RadioButtonProps[] = [
        { label: t(`noOrYesRadioButtons.${Buttons.no}`), value: Buttons.no },
        { label: t(`noOrYesRadioButtons.${Buttons.yes}`), value: Buttons.yes },
    ];

    const initialValues = buildInitialValues(currentSelections);

    const resetAdditionalSupportDetail = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<AdditionalInformationFormValues>): void => {
        setFieldValue('additionalSupportDetail', '', false);
        setFieldTouched('additionalSupportDetail', false, false);
    };

    const onAdditionalSupportChange = (formik: FormikProps<AdditionalInformationFormValues>): void => {
        resetAdditionalSupportDetail(formik);
    };

    const onBackClick = (): void => {
        onBackAction(backButtonTestId, journeyId);
        onBack();
    };

    return (
        <View testId={'additionalInformationView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading', { currentOptionTitle })}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph testId={'introductionParagraph'}>{t('paragraphs.introduction')}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={(values): void => {
                    const selections = buildSelections(t, values);

                    const summaryItem = buildSummaryItem(t);

                    onContinueAction(continueButtonTestId, journeyId);
                    onContinue(selections, summaryItem);
                }}
            >
                {(formik: FormikProps<AdditionalInformationFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-additional-information-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedCheckboxGroup
                                    className={'u-indent'}
                                    label={t('financialCircumstances.label')}
                                    secondaryLabel={t('financialCircumstances.secondaryLabel')}
                                    name={'financialCircumstances'}
                                    testId={'financialCircumstances'}
                                    isMandatory={true}
                                >
                                    {Object.keys(formik.values.financialCircumstances).map((key) => (
                                        <ValidatedCheckboxGroup.Checkbox
                                            key={key}
                                            className={'u-mb-xsmall'}
                                            label={t(`financialCircumstances.${key}.label`)}
                                            tooltip={t(`financialCircumstances.${key}.tooltip`)}
                                            groupName={'financialCircumstances'}
                                            name={`financialCircumstances.${key}`}
                                            testId={`financialCircumstances.${key}`}
                                        />
                                    ))}
                                </ValidatedCheckboxGroup>
                                <ValidatedTextarea
                                    className={'u-indent'}
                                    label={t('financialCircumstancesMoreDetail.label')}
                                    tooltip={t('financialCircumstancesMoreDetail.tooltip')}
                                    name={'financialCircumstancesMoreDetail'}
                                    testId={'financialCircumstancesMoreDetail'}
                                    isMandatory={financialCircumstancesMoreDetailIsMandatory(
                                        formik.values.financialCircumstances.other,
                                    )}
                                    rows={4}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedRadioButtonGroup
                                    className={'u-mb-xsmall'}
                                    label={t('additionalSupport.label')}
                                    radioButtons={noAndYesRadioButtons}
                                    name={'additionalSupport'}
                                    testId={'additionalSupport'}
                                    isMandatory={true}
                                    handleChange={(): void => onAdditionalSupportChange(formik)}
                                />
                                <ValidatedTextarea
                                    className={'u-indent'}
                                    label={t('additionalSupportDetail.label')}
                                    name={'additionalSupportDetail'}
                                    testId={'additionalSupportDetail'}
                                    isMandatory={additionalSupportDetailIsMandatory(
                                        formik.values.additionalSupport as Buttons,
                                    )}
                                    disabled={additionalSupportDetailDisabled(
                                        formik.values.additionalSupport as Buttons,
                                    )}
                                    rows={4}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedRadioButtonGroup
                                    className={'u-mb-xsmall'}
                                    label={t('upToDateWithPriorityBills.label')}
                                    helpText={t('upToDateWithPriorityBills.helpText')}
                                    radioButtons={noAndYesRadioButtons}
                                    name={'upToDateWithPriorityBills'}
                                    testId={'upToDateWithPriorityBills'}
                                    isMandatory={true}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button type={'button'} testId={backButtonTestId} onClick={onBackClick} secondary>
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button type={'button'} testId={continueButtonTestId} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification trackingId={trackingId} journeyId={journeyId} />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
